define("discourse/plugins/discourse-calendar/discourse/controllers/discourse-post-event-invite-user-or-group", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/mixins/modal-functionality"], function (_exports, _controller, _object, _ajax, _ajaxError, _modalFunctionality) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_obj = {
    invitedNames: null,
    onClose() {
      this.set("invitedNames", null);
    },
    invite() {
      return (0, _ajax.ajax)(`/discourse-post-event/events/${this.model.id}/invite.json`, {
        data: {
          invites: this.invitedNames || []
        },
        type: "POST"
      }).then(() => this.send("closeModal")).catch(e => this.flash((0, _ajaxError.extractError)(e), "error"));
    }
  }, (_applyDecoratedDescriptor(_obj, "invite", [_object.action], Object.getOwnPropertyDescriptor(_obj, "invite"), _obj)), _obj));
});